*{
    font-family: Georgia, 'Times New Roman', Times, serif;
}

.card{
    /* outline: 1px red solid; */
    background-color: crimson;
    padding: 2em;
    border-radius: 1em;
}



.card h1{
    background-color: rgb(243, 138, 159);
    padding: 0.5em;
    border-radius: 0.5em;
    text-align: center;
}

.card h1:hover{
    background-color: green;
}

.response{
    background-color: rgb(243, 138, 159);
    padding: 0.5em;
    border-radius: 0.5em
}

li{
    margin-bottom: 0.5em;
}